import React from "react";
import styled from "styled-components";

import Header from "../components/Header";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: #ffffff;
`;
const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  position: relative;
  margin: 0 auto;
  padding: 1rem;
`;

export default () => (
  <>
    <Header ville={process.env.REACT_APP_VILLE} />
    <Wrapper>
      <Container>
        <h1>Page 404 !</h1>
      </Container>
    </Wrapper>
  </>
);
